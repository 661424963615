
import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/LoginView.vue'
import ResetSenha from '../views/ResetSenhaView.vue'
import Home from '../views/HomeView.vue'
import GrupoTelas from '../views/Adm/GrupoTelasView.vue'
import GrupoTelasInstitucional from '../views/Adm/GrupoTelasInstitucionalView.vue'
import CadastroTelas from '../views/Adm/CadastroTelasView.vue'
import ConfigListaImagensTelas from '../views/Adm/ConfigListaImagensTelasView.vue'
import ConfigListaArquivosTelas from '../views/Adm/ConfigListaArquivosTelasView.vue'
import ConfigListaGridsTelas from '../views/Adm/ConfigListaGridsTelasView.vue'

import CadastroTelasInstitucional from '../views/Adm/CadastroTelasInstitucionalView.vue'
import CadastroTipoUsuario from '../views/Adm/CadastroTipoUsuarioView.vue'
import ControleSmtp from '../views/Adm/ControleSmtpView.vue'
import ConfigInstitucional from '../views/Adm/ConfigInstitucionalView.vue'
import ConfigListaImagens from '../views/Adm/ConfigListaImagensView.vue'
import ConfigListaArquivos from '../views/Adm/ConfigListaArquivosView.vue'
import ConfigListaGrids from '../views/Adm/ConfigListaGridsView.vue'
import CadastroPais from '../views/Cadastros/CadastroPaisView.vue'
import CadastroEstados from '../views/Cadastros/CadastroEstadosView.vue'
import CadastroCidades from '../views/Cadastros/CadastroCidadesView.vue'
import CadastroBairros from '../views/Cadastros/CadastroBairrosView.vue'
import CadastroIdiomas from '../views/Cadastros/CadastroIdiomasView.vue'
import CadastroMoedas from '../views/Cadastros/CadastroMoedasView.vue'
import CadastroCotacoes from '../views/Cadastros/CadastroCotacoesView.vue'
import MensagensPadrao from '../views/Cadastros/CadastroMensagensPadraoView.vue'
import CadastroUsuarios from '../views/Usuarios/CadastroUsuariosView.vue'
import ControlePermissoes from '../views/Usuarios/ControlePermissoesView.vue'
import Institucional from '../views/Institucional/InstitucionalView.vue'
import SegmentosInst from '../views/Institucional/SegmentosInstView.vue'

import SegmentosProd from '../views/Produtos/SegmentosProdView.vue'
import ProdutosAvulsos from '../views/Produtos/ProProdutosAvulsosView.vue'
import ProdutosTabelaPreco from '../views/Produtos/ProProdutosTabelaPrecoView.vue'
import Produtos from '../views/Produtos/ProProdutosView.vue'
import ProTextosGerais from '../views/Produtos/ProTextosGeraisView.vue'

import Clientes from '../views/Clientes/CliClientesView.vue'

import Cupons from '../views/Cupom/CupCuponsView.vue'

const routes = [
  { path: '/', name: 'login', component: Login },
  { path: '/Home', name: 'Home', component: Home },
  { path: '/ResetSenha/:id/:key', name: 'ResetSenha', component: ResetSenha },
  { path: '/GrupoTelas', name: 'GrupoTelas', component: GrupoTelas },
  { path: '/GrupoTelasInstitucional', name: 'GrupoTelasInstitucional', component: GrupoTelasInstitucional },
  { path: '/CadastroTelas', name: 'CadastroTelas', component: CadastroTelas },
  { path: '/ConfigListaImagensTelas', name: 'ConfigListaImagensTelas', component: ConfigListaImagensTelas },
  { path: '/ConfigListaArquivosTelas', name: 'ConfigListaArquivosTelas', component: ConfigListaArquivosTelas },
  { path: '/ConfigListaGridsTelas', name: 'ConfigListaGridsTelas', component: ConfigListaGridsTelas },
  { path: '/CadastroTelasInstitucional', name: 'CadastroTelasInstitucional', component: CadastroTelasInstitucional },
  { path: '/CadastroTipoUsuario', name: 'CadastroTipoUsuario', component: CadastroTipoUsuario },
  { path: '/ControleSmtp', name: 'ControleSmtp', component: ControleSmtp },
  { path: '/ConfigInstitucional', name: 'ConfigInstitucional', component: ConfigInstitucional },
  { path: '/ConfigListaImagens', name: 'ConfigListaImagens', component: ConfigListaImagens },
  { path: '/ConfigListaArquivos', name: 'ConfigListaArquivos', component: ConfigListaArquivos },
  { path: '/ConfigListaGrids', name: 'ConfigListaGrids', component: ConfigListaGrids },
  { path: '/CadastroPais', name: 'CadastroPais', component: CadastroPais },
  { path: '/CadastroEstados', name: 'CadastroEstados', component: CadastroEstados },
  { path: '/CadastroCidades', name: 'CadastroCidades', component: CadastroCidades },
  { path: '/CadastroBairros', name: 'CadastroBairros', component: CadastroBairros },
  { path: '/CadastroIdiomas', name: 'CadastroIdiomas', component: CadastroIdiomas },
  { path: '/CadastroMoedas', name: 'CadastroMoedas', component: CadastroMoedas },
  { path: '/CadastroCotacoes', name: 'CadastroCotacoes', component: CadastroCotacoes },
  { path: '/MensagensPadrao', name: 'MensagensPadrao', component: MensagensPadrao },
  { path: '/CadastroUsuarios', name: 'CadastroUsuarios', component: CadastroUsuarios },
  { path: '/ControlePermissoes', name: 'ControlePermissoes', component: ControlePermissoes },
  { path: '/Institucional/:id/:nome', name: 'Institucional', component: Institucional },
  { path: '/SegmentosInst', name: 'SegmentosInst', component: SegmentosInst },
  { path: '/SegmentosProdutos', name: 'SegmentosProd', component: SegmentosProd },
  { path: '/ProdutosAvulsos', name: 'ProdutosAvulsos', component: ProdutosAvulsos },
  { path: '/ProdutosTabelaPreco', name: 'ProdutosTabelaPreco', component: ProdutosTabelaPreco },
  { path: '/Produtos', name: 'Produtos', component: Produtos },
  { path: '/ProdutosTextosGerais', name: 'ProTextosGerais', component: ProTextosGerais },
  { path: '/Clientes', name: 'Clientes', component: Clientes },
  { path: '/Cupons', name: 'Cupons', component: Cupons },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  next()
})

export default router
