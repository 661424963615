<template>
    <div>
        <div class="faq-wrap">
            <div class="card-box bordasEsp">
                <div class="card-header">
                    <div class="clearfix">
                        <div class="pull-left">
                            <h4 class="text-black h4">Controle de permissões</h4>
                        </div>
                    </div>
                </div>
                <div class="row" style="padding-top: 20px;">
                    <div class="col-md-12">
                        <div class="form-group">
                            <v-select v-model="UserSelected" label="Selecione Usuário" :items="Usuarios" item-title="cusNome"
                                item-value="cusId" variant="underlined" @update:modelValue="CarregaTelas(UserSelected)">
                            </v-select>
                        </div>
                    </div>
                    <div class="col-md-6" style="padding-top: 20px;">
                        <h6>Telas Gerais</h6>
                        <div class="row">
                            <v-checkbox v-model="selectAll" label="Selecionar todos" @change="toggleSelectAll"></v-checkbox>
                        </div>
                        <div class="d-flex flex-row">
                            <v-tabs v-model="tab" direction="vertical" color="primary" style="width: 200px;border-right: 1px solid #cccccc;" >
                                <v-tab :value="grupo.grtNome" v-for="grupo in GrupoTelas" :key="grupo.grtId">
                                    <v-icon start>{{ grupo.grtIcon }}</v-icon>
                                    {{ grupo.grtNome }}
                                </v-tab>
                            </v-tabs>
                            <v-window v-model="tab">
                                <v-window-item :value="tela.admGrupoTelas.grtNome" v-for="tela in CadastroTelas" :key="tela.cdtId">
                                 <v-checkbox v-model="selected" :label="tela.cdtNome" :value="tela.cdtId" checked="true"></v-checkbox>
                                </v-window-item>
                            </v-window>
                        </div>
                    </div>
                    <div class="col-md-6" style="padding-top: 20px;">
                        <h6>Telas Institucionais</h6>
                        <div class="row">
                            <v-checkbox v-model="selectAll2" label="Selecionar todos" @change="toggleSelectAll2"></v-checkbox>
                        </div>
                        <div class="d-flex flex-row">
                            <v-tabs v-model="tab2" direction="vertical" color="primary" style="width: 200px;border-right: 1px solid #cccccc;" >
                                <v-tab :value="grupoi.gtiNome" v-for="grupoi in GrupoTelas2" :key="grupoi.gtiId">
                                    <v-icon start>{{ grupoi.gtiIcon }}</v-icon>
                                    {{ grupoi.gtiNome }}
                                </v-tab>
                            </v-tabs>
                            <v-window v-model="tab2">
                                <v-window-item :value="telai.admGrupoTelasInst.gtiNome" v-for="telai in CadastroTelas2" :key="telai.ctiId">
                                 <v-checkbox v-model="selected2" :label="telai.ctiNome" :value="telai.ctiId" checked="true"></v-checkbox>
                                </v-window-item>
                            </v-window>
                        </div>
                    </div>
                    <div class="col-md-12 text-center" style="padding-top: 40px;">
                        <button type="button" class="btn btn-link">Cancelar</button>
                        <button type="button" class="btn btn-success" id="sa-custom-position" @click="Add()">Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'

export default {
    name: 'ControlePermissoesView',
    components: {
        moment
    },
    data: () => ({
        Usuarios: [],
        UserSelected: '',
        //geral
        tab: '',
        selectAll: false,
        selected:[],
        GrupoTelas: [],
        CadastroTelas: [],
        TelasUser:[],
        //institucional
        tab2: '',
        selectAll2: false,
        selected2:[],
        GrupoTelas2: [],
        CadastroTelas2: [],
        TelasUser2:[],
    }),
    methods: {
        toggleSelectAll() {
            if (this.selectAll) {
                this.selected = this.CadastroTelas.map((tela) => tela.cdtId);
            } else {
                this.selected = [];
            }
        },
        toggleSelectAll2() {
            if (this.selectAll2) {
                this.selected2 = this.CadastroTelas2.map((tela2) => tela2.ctiId);
            } else {
                this.selected2 = [];
            }
        },
        markSelectedCheckboxes() {
            this.selected = this.TelasUser.map(telaUser => telaUser.cdtId);
        },
        markSelectedCheckboxes2() {
            this.selected2 = this.TelasUser2.map(telaUser2 => telaUser2.cdtId);
        },
        Add(){
            if (this.UserSelected == '') {
                this.$mensagemAviso("Usuário Obrigatório.")
            }
            else {
                const param1 = this.UserSelected;
                axios.delete(`/Process/UsrControlePermissoes/DeletebyUserId/${param1}`)
                    .then(response => {
                        if (response.status == 200 || response.status == 404) {
                            let selectedPromise = Promise.all(this.selected.map(element => {
                                const Permissoes = {
                                    cusId: parseInt(this.UserSelected),
                                    cdtId: parseInt(element),
                                    cusTipo: 1
                                };
                                return axios.post(`/Process/UsrControlePermissoes/Add`, Permissoes)
                                    .then(response => {
                                        if (response.data > 0) {
                                            return null;
                                        }
                                    });
                            }));

                            let selected2Promise = Promise.all(this.selected2.map(element => {
                                const Permissoes2 = {
                                    cusId: parseInt(this.UserSelected),
                                    cdtId: parseInt(element),
                                    cusTipo: 2
                                };
                                return axios.post(`/Process/UsrControlePermissoes/Add`, Permissoes2)
                                    .then(response => {
                                        if (response.data > 0) {
                                            return null;
                                        }
                                    });
                            }));

                            Promise.all([selectedPromise, selected2Promise]).then(results => {
                                // Todas as chamadas assíncronas foram concluídas
                                this.$mensagemAviso("Permissões do usuário atualizadas.")
                            });
                        }
                    });
            }
        },
        CarregaTelas(value){
            const param1 = value;
            axios.get(`/Process/UsrControlePermissoes/GetAllByUserId/${param1}`)
                .then(response => {
                    this.TelasUser = response.data.filter(x=> x.cusTipo == 1);
                    this.TelasUser2 = response.data.filter(x=> x.cusTipo == 2);
                    this.markSelectedCheckboxes();
                    this.markSelectedCheckboxes2();
                    this.selectAll = false;
                    this.selectAll2 = false;
                });
        },
        Get() {

            //geral
            axios.get(`/Process/AdmGrupoTelas/GetAll`)
                .then(response => {
                    this.GrupoTelas = response.data;
                    this.tab = this.GrupoTelas[0].grtNome;
                });


                axios.get(`Process/AdmCadastroTelas/GetAll`)
            .then(response => {
                this.CadastroTelas = response.data.filter(x => x.cdtVisivel).sort((a, b) => {
                        const nomeA = a.cdtNome || "";
                        const nomeB = b.cdtNome || "";
                        return nomeA.localeCompare(nomeB);
                    });
            });

            //institucional            
            axios.get(`/Process/AdmGrupoTelasInst/GetAll`)
                .then(response => {
                    this.GrupoTelas2 = response.data;
                    this.tab2 = this.GrupoTelas2[0].gtiNome;
                });

            axios.get(`Process/AdmCadastroTelasInst/GetAll`)
            .then(response => {
                this.CadastroTelas2 = response.data.filter(x => x.ctiVisivel).sort((a, b) => {
                        const nomeA = a.ctiNome || "";
                        const nomeB = b.ctiNome || "";
                        return nomeA.localeCompare(nomeB);
                    });
            });

            axios.get(`/Process/UsrCadastroUsuarios/GetAll`)
                .then(response => {
                    this.Usuarios = response.data.filter(x => x.cusAtivo && x.cusId != 2).sort((a, b) => { 
                        const nomeA = a.cusNome || "";
                        const nomeB = b.cusNome || "";
                        return nomeA.localeCompare(nomeB);
                    });
                });

         
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style>
.nav-tabs {
    margin-bottom: 20px;
}

.v-input__details {
display: none !important;
}

.v-label{
    padding-top: 7px;
}

</style>
